import * as React from 'react';
import {
  GDSSelectProps,
  Select,
  StackItem,
  StackLayout,
  UtilityText,
} from '@leagueplatform/genesis-core';
import type { RulesTemplate } from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';

interface JsonLogicInputTemplateSelectProps {
  rulesTemplates: RulesTemplate[];
  onChange: GDSSelectProps['onChange'];
  selectedTemplate?: GDSSelectProps['value'];
  templateLabel: string;
  id: string;
  disabled?: boolean;
}

export const JsonLogicInputTemplateSelect = ({
  rulesTemplates,
  onChange,
  selectedTemplate,
  templateLabel,
  id,
  disabled,
}: JsonLogicInputTemplateSelectProps) => {
  const { formatMessage } = useIntl();
  const rulesTemplateOptions = React.useMemo(
    () => [
      {
        value: 'none',
        label: formatMessage({ id: 'EMPTY_RULES_TEMPLATE' }),
      },
      ...rulesTemplates.map((template) => ({
        value: template.id,
        label: formatMessage({ id: template.label }),
      })),
    ],
    [formatMessage, rulesTemplates],
  );

  return (
    <StackLayout
      data-testid="json-logic-input-template-select"
      orientation="horizontal"
      verticalAlignment="center"
      css={{
        width: '100%',
        paddingBottom: '$one',
      }}
    >
      <StackItem
        css={{
          width: '100%',
        }}
      >
        <UtilityText
          as="label"
          htmlFor={`rule-template-${id}`}
          css={{ flexGrow: 0, paddingBottom: '$half', paddingTop: '$one' }}
          emphasis="subtle"
          size="xs"
        >
          {templateLabel}
        </UtilityText>
        <Select
          options={rulesTemplateOptions}
          onChange={onChange}
          value={selectedTemplate}
          id={`rule-template-${id}`}
          disabled={disabled}
        />
      </StackItem>
    </StackLayout>
  );
};
