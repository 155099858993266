import { DataPoint, DataPoints, DataType } from '@web-config-app/core';

export function filterDataPointsByDataGroupRecursive(
  dataPoints: DataPoint[] | undefined,
  dataGroupsToFilter: string[],
): DataPoint[] {
  if (!dataPoints) {
    return [];
  }

  if (dataGroupsToFilter === null || dataGroupsToFilter.length === 0) {
    return dataPoints;
  }

  return dataPoints.reduce((filtered: DataPoint[], dataPoint: DataPoint) => {
    const matchesDataGroup = dataGroupsToFilter.includes(dataPoint.id);
    const filteredDataPoints: DataPoint[] =
      filterDataPointsByDataGroupRecursive(
        dataPoint.dataPoints,
        dataGroupsToFilter,
      );

    // Include the current data point if it matches the data group OR it has children that match the data group
    if (matchesDataGroup || filteredDataPoints.length > 0) {
      const newDataPoint: DataPoint = { ...dataPoint };
      if (filteredDataPoints.length > 0) {
        newDataPoint.dataPoints = filteredDataPoints;
      }
      filtered.push(newDataPoint);
    }
    return filtered;
  }, []);
}

export function filterDataPointsByTypeRecursive(
  dataPoints: DataPoint[] | undefined,
  dataTypesToFilter: DataType[],
): DataPoint[] {
  if (!dataPoints) {
    return [];
  }

  if (dataTypesToFilter.length === 0) {
    return dataPoints;
  }
  return dataPoints.reduce((filtered: DataPoint[], dataPoint: DataPoint) => {
    const matchesDataType = dataTypesToFilter.includes(dataPoint.dataType);
    const nestedFilteredDataPoints = dataPoint.dataPoints
      ? filterDataPointsByTypeRecursive(dataPoint.dataPoints, dataTypesToFilter)
      : [];
    if (matchesDataType || nestedFilteredDataPoints.length > 0) {
      const newDataPoint: DataPoint = { ...dataPoint };
      if (nestedFilteredDataPoints.length > 0) {
        newDataPoint.dataPoints = nestedFilteredDataPoints;
      }
      filtered.push(newDataPoint);
    }

    return filtered;
  }, []);
}

export function filterDataPointsByTypeAndGroup(
  dataPoints: DataPoints,
  dataTypesToFilter: DataType[],
  dataGroups: string[],
): DataPoints {
  return dataPoints.map((flowTypeDataPoint) => {
    let filteredDataPoints = filterDataPointsByDataGroupRecursive(
      flowTypeDataPoint.dataPoints,
      dataGroups,
    );

    filteredDataPoints = filterDataPointsByTypeRecursive(
      filteredDataPoints,
      dataTypesToFilter,
    );

    return {
      ...flowTypeDataPoint,
      dataPoints: filteredDataPoints,
    };
  });
}
