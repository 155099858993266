import * as React from 'react';
import type { AnnotatedJsonSchema } from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import { Box } from '@leagueplatform/genesis-core';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import { useConfigApp } from '@web-config-app/core-react';
import type { ControlComponent } from '../../../types/controls';
import { EntityFormControl } from '../../entity-form-control/entity-form-control.component';
import { JsonLogicInputTemplateSelect } from './json-logic-input-template-select.component';
import { RuleTemplateForm } from './rule-template-form/rule-template-form.component';
import { ruleTemplateJsonLogicInputControlRenderer } from '../../../renderers/rule-template-json-logic-renderer';
import { JsonLogicInputControlWrapper } from './json-logic-input-control-wrapper.component';
import { RuleCombinationControlProvider } from './rule-combination-provider/rule-combination-provider.component';
import { useJsonLogicControl } from './use-json-logic-control';
import { OldJsonLogicInputControlContents } from './old-json-logic-input-control/old-json-logic-input-control.component';
import { useEntityDetailsProps } from '../../../hooks/use-entity-details-props/use-entity-details-props';

/**
 * The rule combination control is the top level json logic control that determines what other json-logic controls should render (a single rule or a combination aka array of rules)
 * The Rule combination control get its data not really from the data prop, but from the ruleTemplateFormData that is returned from useRuleCombinationControl, which in turn gets its data from the Editors data store.
 *
 * However there is a bug right now that on refresh when you are on a page with this component, the data is not loaded from the store right away, so the form is empty. Maybe we need to do something with the data prop on first load?
 */
const RuleCombinationControlContents = ({
  handleChange,
  path,
  enabled,
  schema,
}: {
  handleChange: (path: string, value: any) => void;
  path: string;
  enabled: boolean;
  schema: AnnotatedJsonSchema;
}) => {
  const { formatMessage } = useIntl();

  const {
    rulesTemplates,
    selectedRuleTemplate,
    ruleTemplateFormData,
    handleRuleTemplateFormDataChange,
    handleTemplateChange,
    jsonLogicFieldPath,
  } = useJsonLogicControl({
    handleChange,
    path,
    schema,
  });

  const isArraySchema =
    selectedRuleTemplate?.schema?.properties?.ruleCombination.type === 'array';

  const showTemplateSelect = rulesTemplates;

  return (
    <RuleCombinationControlProvider
      combinationFieldPath={jsonLogicFieldPath}
      isArray={isArraySchema}
    >
      <Box
        css={{
          width: '100%',
        }}
      >
        {showTemplateSelect && (
          <JsonLogicInputTemplateSelect
            rulesTemplates={rulesTemplates}
            onChange={handleTemplateChange}
            selectedTemplate={selectedRuleTemplate?.id}
            templateLabel={formatMessage({ id: 'COMBINE_RULES_WITH' })}
            id={path}
            disabled={!enabled}
          />
        )}
        <RuleTemplateForm
          selectedRuleTemplate={selectedRuleTemplate}
          ruleTemplateData={ruleTemplateFormData}
          onRuleTemplateDataChange={handleRuleTemplateFormDataChange}
          renderers={[ruleTemplateJsonLogicInputControlRenderer]}
        />
      </Box>
    </RuleCombinationControlProvider>
  );
};

/**
 * Main JSON Logic Input Control Renderer for Rule Combination - used to render the Rule Combination control
 */
export const RuleCombinationJsonLogicInputControlRenderer: ControlComponent = (
  props,
) => {
  const { isUsingConfigAppBackend: tenantIsUsingConfigAppBackend } =
    useConfigApp();
  const { entity } = useEntityDetailsProps();
  const isConfigAppBE = isUsingConfigAppBackend({
    tenantLevelOverride: tenantIsUsingConfigAppBackend,
    entityLevelOverride: entity?.isUsingConfigAppBackend,
  });
  return (
    <EntityFormControl
      {...props}
      renderControl={
        isConfigAppBE
          ? RuleCombinationControlContents
          : OldJsonLogicInputControlContents
      }
      wrapperComponent={JsonLogicInputControlWrapper}
    />
  );
};
