import type { RulesTemplateWithoutEntityType } from '@web-config-app/core';
import { mapRulesTemplatesForEntity } from '../../utils/map-rules-templates-for-entity';
import { commonMasonryFilterRulesTemplates } from './masonry-rules-templates';

const commonActivityRulesTemplatePartials: RulesTemplateWithoutEntityType[] = [
  {
    name: 'Complete any campaign',
    id: 'activity_complete_any_campaign',
    label: 'COMPLETE_ANY_CAMPAIGN',
    type: 'completionCriteria',
    rules: {
      '==': [
        {
          var: 'campaign.all_eligible_activities_completed',
        },
        true,
      ],
    },
  },
  {
    id: 'activity_complete_any_campaign_by_type',
    name: 'Complete any campaign by type',
    label: 'COMPLETE_ANY_CAMPAIGN_BY_TYPE',
    type: 'completionCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'campaign.all_eligible_activities_completed',
            },
            true,
          ],
        },
        {
          '==': [
            {
              var: 'campaign.type',
            },
            '{campaignType}',
          ],
        },
      ],
    },
    inputs: {
      campaignType: {
        type: 'string',
        enum: ['opt-in', 'suggested', 'challenge', 'reward'],
        'x-entity-label': {
          key: 'CAMPAIGN_TYPE',
        },
      },
    },
  },
  {
    id: 'activity_complete_specific_campaign_or_challenge',
    name: 'Complete a specific campaign or challenge',
    label: 'COMPLETE_SPECIFIC_CAMPAIGN_OR_CHALLENGE',
    type: 'completionCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'campaign.all_eligible_activities_completed',
            },
            true,
          ],
        },
        {
          '==': [
            {
              var: 'campaign.id',
            },
            '{campaignId}',
          ],
        },
      ],
    },
    inputs: {
      campaignId: {
        type: 'string',
        'x-entity-label': {
          key: 'CAMPAIGN_ID',
        },
      },
    },
  },
  {
    name: 'Award points based on max points threshold',
    id: 'activity_award_points_max_points_threshold',
    label: 'ACTIVITIES_AWARD_POINTS_MAX_POINTS_THRESHOLD',
    type: 'rewardCriteria',
    rules: {
      if: [
        {
          '>=': [
            {
              var: 'reward.reward_points.total_campaign_points_earned',
            },
            {
              var: 'reward.reward_points.max_campaign_redemption_points',
            },
          ],
        },
        0,
        {
          if: [
            {
              '<=': [
                {
                  '+': [
                    {
                      var: 'reward.reward_points.activity_points',
                    },
                    {
                      var: 'reward.reward_points.total_campaign_points_earned',
                    },
                  ],
                },
                {
                  var: 'reward.reward_points.max_campaign_redemption_points',
                },
              ],
            },
            {
              var: 'reward.reward_points.activity_points',
            },
            {
              '-': [
                {
                  var: 'reward.reward_points.max_campaign_redemption_points',
                },
                {
                  var: 'reward.reward_points.total_campaign_points_earned',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    name: 'Complete the reward goal set for a Challenge',
    id: 'activity_complete_reward_goal_set_for_challenge',
    label: 'ACTIVITY_COMPLETE_REWARD_GOAL_SET_FOR_CHALLENGE',
    type: 'rewardCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'campaign.reward_program_goal_met',
            },
            true,
          ],
        },
        {
          '==': [
            {
              var: 'campaign.type',
            },
            'challenge',
          ],
        },
      ],
    },
  },
  {
    name: 'Complete X number of activities for a specific Campaign or Challenge',
    id: 'activity_complete_x_activities_for_specific_campaign_or_challenge',
    label: 'ACTIVITY_COMPLETE_X_ACTIVITIES_FOR_SPECIFIC_CAMPAIGN_OR_CHALLENGE',
    type: 'completionCriteria',
    rules: {
      and: [
        {
          '==': [
            {
              var: 'campaign.id',
            },
            '{campaignId}',
          ],
        },
        {
          '==': [
            {
              var: 'campaign.completed_activity_count',
            },
            '{completedActivityCount}',
          ],
        },
      ],
    },
    inputs: {
      campaignId: {
        type: 'string',
        'x-entity-label': {
          key: 'CAMPAIGN_ID',
        },
      },
      completedActivityCount: {
        type: 'integer',
        'x-entity-label': {
          key: 'COMPLETED_ACTIVITY_COUNT',
        },
      },
    },
  },
  {
    id: 'activity_reward_points_biometric_observation_value',
    name: 'Reward points based on the value of a biometric observation',
    label: 'ACTIVITY_REWARD_POINTS_BIOMETRIC_OBSERVATION_VALUE',
    type: 'rewardCriteria',
    rules: {
      if: [
        {
          '>=': [
            {
              var: 'reward.reward_points.total_campaign_points_earned',
            },
            {
              var: 'reward.reward_points.max_campaign_redemption_points',
            },
          ],
        },
        0,
        {
          if: [
            {
              and: [
                {
                  '>=': [
                    {
                      var: '{pathToObservationValue}',
                    },
                    '{minimumValue}',
                  ],
                },
                {
                  '<=': [
                    {
                      var: '{pathToObservationValue}',
                    },
                    '{maximumValue}',
                  ],
                },
              ],
            },
            {
              if: [
                {
                  '<=': [
                    {
                      '+': [
                        {
                          var: 'reward.reward_points.activity_points',
                        },
                        {
                          var: 'reward.reward_points.total_campaign_points_earned',
                        },
                      ],
                    },
                    {
                      var: 'reward.reward_points.max_campaign_redemption_points',
                    },
                  ],
                },
                {
                  var: 'reward.reward_points.activity_points',
                },
                {
                  '-': [
                    {
                      var: 'reward.reward_points.max_campaign_redemption_points',
                    },
                    {
                      var: 'reward.reward_points.total_campaign_points_earned',
                    },
                  ],
                },
              ],
            },
            0,
          ],
        },
      ],
    },
    inputs: {
      minimumValue: {
        type: 'integer',
        'x-entity-label': {
          key: 'MINIMUM_VALUE',
        },
        default: 0,
      },
      maximumValue: {
        type: 'integer',
        'x-entity-label': {
          key: 'MAXIMUM_VALUE',
        },
      },
      pathToObservationValue: {
        type: 'string',
        'x-entity-label': {
          key: 'PATH_TO_OBSERVATION_VALUE',
        },
        default: 'FHIR.Observation.sbp.valueQuantity.value',
      },
    },
  },
];

export const commonActivityRulesTemplates =
  commonActivityRulesTemplatePartials.map(
    mapRulesTemplatesForEntity('config-activity'),
  );

export const commonCampaignRulesTemplatePartials: RulesTemplateWithoutEntityType[] =
  [
    {
      id: 'campaign_complete_campaign_meet_reward_point_threshold',
      name: 'Complete Campaign based on meeting Reward point threshold',
      label: 'COMPLETE_CAMPAIGN_MEET_REWARD_POINT_THRESHOLD',
      type: 'completionCriteria',
      rules: {
        '>=': [
          {
            var: 'campaign.total_points_earned',
          },
          {
            var: 'campaign.max_redemption_points',
          },
        ],
      },
    },
    {
      id: 'campaign_program_segment_by_age',
      name: 'Eligibility based on user age',
      label: 'CAMPAIGN_PROGRAM_BY_AGE',
      type: 'eligibilityCriteria',
      rules: {
        '>=': [
          {
            var: 'up.age',
          },
          '{age}',
        ],
      },
      inputs: {
        age: {
          type: 'integer',
          'x-entity-label': {
            key: 'AGE',
          },
        },
      },
    },
    {
      id: 'campaign_program_with_one_rule_condition',
      label: 'CAMPAIGN_PROGRAM_WITH_ONE_RULE_CONDITION',
      name: 'Program with 1 rule condition (String)',
      type: 'eligibilityCriteria',
      rules: {
        string_equal_ignore_case: [
          '{condition}',
          {
            var: '{dataPoint}',
          },
        ],
      },
      inputs: {
        condition: {
          type: 'string',
          'x-entity-label': {
            key: 'CONDITION',
          },
        },
        dataPoint: {
          type: 'string',
          'x-entity-label': {
            key: 'DATA_POINT',
          },
          'x-entity-data-point': {
            dataPointSources: ['config'],
          },
        },
      },
    },
    {
      id: 'campaign_program_with_not_condition',
      label: 'CAMPAIGN_PROGRAM_WITH_NOT_CONDITION',
      name: 'Program with not condition (String)',
      type: 'eligibilityCriteria',
      rules: {
        '!': {
          string_equal_ignore_case: [
            '{condition}',
            {
              var: '{dataPoint}',
            },
          ],
        },
      },
      inputs: {
        condition: {
          type: 'string',
          'x-entity-label': {
            key: 'CONDITION',
          },
        },
        dataPoint: {
          type: 'string',
          'x-entity-label': {
            key: 'DATA_POINT',
          },
          'x-entity-data-point': {
            dataPointSources: ['config'],
          },
        },
      },
    },
    {
      id: 'campaign_program_with_all_match',
      label: 'CAMPAIGN_PROGRAM_WITH_ALL_MATCH',
      name: 'Program with all match (String)',
      type: 'eligibilityCriteria',
      rules: {
        all_strings_in_list_match_ignore_case: [
          '{stringList}',
          {
            var: '{dataPoint}',
          },
        ],
      },
      inputs: {
        stringList: {
          type: 'array',
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'ALL_STRINGS_IN_LIST',
            },
            'x-entity-presentation': {
              hideLabel: true,
            },
          },
          'x-entity-label': {
            key: 'ALL_STRINGS_IN_LIST',
          },
        },
        dataPoint: {
          type: 'string',
          'x-entity-label': {
            key: 'DATA_POINT',
          },
          'x-entity-data-point': {
            dataPointSources: ['config'],
          },
        },
      },
    },
    {
      id: 'campaign_program_with_any_match',
      label: 'CAMPAIGN_PROGRAM_WITH_ANY_MATCH',
      name: 'Program with any match (String)',
      type: 'eligibilityCriteria',
      rules: {
        any_string_in_list_match_ignore_case: [
          '{stringList}',
          {
            var: '{dataPoint}',
          },
        ],
      },
      inputs: {
        stringList: {
          type: 'array',
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'ALL_STRINGS_IN_LIST',
            },
            'x-entity-presentation': {
              hideLabel: true,
            },
          },
          'x-entity-label': {
            key: 'ALL_STRINGS_IN_LIST',
          },
        },
        dataPoint: {
          type: 'string',
          'x-entity-label': {
            key: 'DATA_POINT',
          },
          'x-entity-data-point': {
            dataPointSources: ['config'],
          },
        },
      },
    },
    {
      id: 'campaign_program_with_any_match_and_joined_before_days',
      label: 'CAMPAIGN_PROGRAM_WITH_ANY_MATCH_AND_JOINED_BEFORE_DAYS',
      name: 'Program with any match and joined before days',
      type: 'eligibilityCriteria',
      rules: {
        and: [
          {
            any_string_in_list_match_ignore_case: [
              {
                var: '{stringListDataPoint}',
              },
              '{stringList}',
            ],
          },
          {
            date_time_diff_gt_eq_days: [
              {
                var: 'context.current_timestamp',
              },
              {
                var: 'up.joined_timestamp',
              },
              '{joinedBeforeDays}',
            ],
          },
        ],
      },
      inputs: {
        stringListDataPoint: {
          type: 'string',
          'x-entity-label': {
            key: 'STRING_LIST_DATA_POINT',
          },
        },
        stringList: {
          type: 'array',
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'STRING_LIST',
            },
            'x-entity-presentation': {
              hideLabel: true,
            },
          },
          'x-entity-label': {
            key: 'STRING_LIST',
          },
        },
        joinedBeforeDays: {
          type: 'integer',
          'x-entity-label': {
            key: 'JOINED_BEFORE_DAYS',
          },
        },
      },
    },
    {
      id: 'campaign_eligibility_segment_even_and_odd_age',
      label: 'CAMPAIGN_ELIGIBILITY_SEGMENT_EVEN_AND_ODD_AGE',
      name: 'Segment by Even and Odd Age',
      type: 'eligibilityCriteria',
      rules: {
        '==': [
          {
            '%': [{ var: 'up.age' }, 2],
          },
          0,
        ],
      },
    },
    {
      id: 'campaign_eligibility_completed_campaign_before_date',
      label: 'CAMPAIGN_ELIGIBILITY_COMPLETED_CAMPAIGN_BEFORE_DATE',
      name: 'Check if the user completed a campaign before a certain date',
      type: 'eligibilityCriteria',
      rules: {
        some: [
          {
            filter: [
              { var: 'ucc.completed_campaigns' },
              { '==': [{ var: 'campaign_id' }, '{contentfulId}'] },
            ],
          },
          { '<': [{ var: 'completion_date' }, '{cutoffDate}'] },
        ],
      },
      inputs: {
        contentfulId: {
          type: 'string',
          'x-entity-label': {
            key: 'CONTENTFUL_ID',
          },
        },
        cutoffDate: {
          type: 'string',
          format: 'date-time',
          'x-entity-label': {
            key: 'CUTOFF_DATE',
          },
        },
      },
    },
    {
      id: 'campaign_branching_waiting_for_all_active_activities_to_be_terminated',
      label:
        'CAMPAIGN_BRANCHING_WAITING_FOR_ALL_ACTIVE_ACTIVITIES_TO_BE_TERMINATED',
      name: 'Wait for all active activities to be terminated before proceeding',
      type: 'branchingRule',
      rules: {
        '!': [{ var: 'campaign_execution.active_activity_ids' }],
      },
    },
    {
      id: 'campaign_branching_user_risk_factor_input_is_one_of_values',
      label: 'CAMPAIGN_BRANCHING_USER_RISK_FACTOR_INPUT_IS_ONE_OF_VALUES',
      name: 'User risk factor input is one of values',
      type: 'branchingRule',
      rules: {
        in: [{ var: 'urfi.code' }, ['{valueOne}', '{valueTwo}']],
      },
      inputs: {
        valueOne: {
          type: 'integer',
          'x-entity-label': {
            key: 'VALUE_ONE',
          },
        },
        valueTwo: {
          type: 'integer',
          'x-entity-label': {
            key: 'VALUE_TWO',
          },
        },
      },
    },
    {
      id: 'campaign_branching_questionnaire_response_is_one_of_values',
      label: 'CAMPAIGN_BRANCHING_QUESTIONNAIRE_RESPONSE_IS_ONE_OF_VALUES',
      name: 'Questionnaire response is one of values',
      type: 'branchingRule',
      rules: {
        in: [
          {
            var: 'lpf.LeagueHealthProfiles-CodeSystem-hp-primary-observation-code-code-system.question_code.value',
          },
          '{answerCodes}',
        ],
      },
      inputs: {
        answerCodes: {
          type: 'array',
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'ANSWER_CODES',
            },
            'x-entity-presentation': {
              hideLabel: true,
            },
          },
          'x-entity-label': {
            key: 'ANSWER_CODES',
          },
        },
      },
    },
    {
      id: 'campaign_challenge_same_company',
      label: 'CAMPAIGN_CHALLENGE_RULE_SAME_COMPANY',
      name: 'Users from the same company (clientId).',
      type: 'challengeRule',
      rules: {
        if: [
          {
            '!!': {
              var: 'client_id',
            },
          },
          {
            var: 'client_id',
          },
          'false',
        ],
      },
    },
    {
      id: 'campaign_challenge_specific_company',
      label: 'CAMPAIGN_CHALLENGE_RULE_SPECIFIC_COMPANY',
      name: 'Users from a specific company (clientId).',
      type: 'challengeRule',
      rules: {
        if: [
          {
            and: [
              {
                '==': [
                  {
                    var: 'client_id',
                  },
                  '{clientId}',
                ],
              },
            ],
          },
          '{clientId}',
          'false',
        ],
      },
      inputs: {
        clientId: {
          type: 'string',
          'x-entity-label': {
            key: 'CLIENT_ID',
          },
        },
      },
    },
  ];

export const commonCampaignRulesTemplates =
  commonCampaignRulesTemplatePartials.map(
    mapRulesTemplatesForEntity('config-campaign'),
  );

const numericOperators = ['===', '>', '<'];
export const commonCohortRulesTemplatePartials: RulesTemplateWithoutEntityType[] =
  [
    {
      id: 'users_age',
      name: 'User Age',
      label: 'USERS_AGE',
      type: 'cohortRule',
      rules: {
        '{numericOperator}': [
          {
            var: 'up.age',
          },
          '{age}',
        ],
      },
      inputs: {
        numericOperator: {
          type: 'string',
          'x-entity-presentation': {
            enumLabels: {
              '===': 'EQUALS',
              '<': 'LESS_THAN',
              '>': 'GREATER_THAN',
            },
          },
          'x-entity-label': {
            key: 'OPERATOR',
          },
          enum: numericOperators,
        },
        age: {
          type: 'integer',
          'x-entity-label': {
            key: 'AGE',
          },
        },
      },
    },
    {
      id: 'location',
      name: 'User Location',
      label: 'USERS_LOCATION',
      type: 'cohortRule',
      rules: {
        any_string_in_list_match_ignore_case: [
          {
            var: '{datapoint}',
          },
          '{matching_strings}',
        ],
      },
      inputs: {
        datapoint: {
          type: 'string',
          'x-entity-presentation': {
            enumLabels: {
              'up.city': 'CITY',
              'up.district': 'DISTRICT',
              'up.state': 'STATE',
              'up.postal_code': 'POSTAL_CODE',
              'up.country': 'COUNTRY',
            },
          },
          'x-entity-label': {
            key: 'LOCATION_DATAPOINT',
          },
          enum: [
            'up.city',
            'up.district',
            'up.state',
            'up.postal_code',
            'up.country',
          ],
        },
        matching_strings: {
          type: 'array',
          'x-entity-label': {
            key: 'MATCHING_STRINGS',
          },
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'STRING_TO_MATCH',
            },
            'x-entity-presentation': {
              hideLabel: true,
            },
          },
        },
      },
    },
    {
      id: 'health_risks',
      name: 'Health Risks',
      label: 'HEALTH_RISKS',
      type: 'cohortRule',
      rules: {
        any_string_in_list_match_ignore_case: [
          {
            var: 'up.health_risks',
          },
          '{matching_strings}',
        ],
      },
      inputs: {
        matching_strings: {
          type: 'array',
          'x-entity-label': {
            key: 'HEALTH_RISKS_DATAPOINTS',
          },
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'HEALTH_RISK',
            },
            'x-entity-presentation': {
              hideLabel: true,
            },
            'x-entity-data-point': {
              dataPointSources: ['dml'],
              dataGroups: [
                'lpf.LeagueUserSegmentation-CodeSystem-user-seg-problems-segment-code-system',
              ],
            },
          },
        },
      },
    },
    {
      id: 'biometric_value',
      name: 'Biometric Observation',
      label: 'BIOMETRIC_OBSERVATION',
      type: 'cohortRule',
      rules: {
        '{numericOperator}': [
          {
            var: '{datapoint}',
          },
          '{value}',
        ],
      },
      inputs: {
        datapoint: {
          type: 'string',
          'x-entity-label': {
            key: 'DATAPOINT',
          },
          'x-entity-data-point': {
            dataPointSources: ['dml'],
          },
        },
        numericOperator: {
          type: 'string',
          'x-entity-presentation': {
            enumLabels: {
              '===': 'EQUALS',
              '<': 'LESS_THAN',
              '>': 'GREATER_THAN',
            },
          },
          'x-entity-label': {
            key: 'OPERATOR',
          },
          enum: numericOperators,
        },
        value: {
          type: 'integer',
          'x-entity-label': {
            key: 'VALUE',
          },
        },
      },
    },
    {
      id: 'health_campaign_status',
      name: 'Health Campaign Completed',
      label: 'HEALTH_CAMPAIGN_STATUS',
      type: 'cohortRule',
      rules: {
        '==': [
          { var: 'ucc.completed_campaigns' },
          { '==': [{ var: 'campaign_id' }, '{campaignId}'] },
        ],
      },
      inputs: {
        campaignId: {
          type: 'string',
          'x-entity-label': {
            key: 'CAMPAIGN_ID',
          },
          'x-entity-reference': {
            domainEntityType: 'config-campaign',
          },
        },
      },
    },
    {
      id: 'activity_completed',
      name: 'Activity Completed',
      label: 'ACTIVITY_STATUS_RULE',
      type: 'cohortRule',
      rules: {
        '==': [
          {
            activity_status: [
              {
                var: '{contentful_activity_id}',
              },
            ],
          },
          '{status}',
        ],
      },
      inputs: {
        contentful_activity_id: {
          type: 'string',
          'x-entity-label': {
            key: 'ACTIVITY_ID',
          },
          'x-entity-reference': {
            domainEntityType: 'config-activity',
          },
        },
        status: {
          type: 'string',
          'x-entity-label': {
            key: 'ACTIVITY_STATUS',
          },
          enum: ['completed', 'active', 'inactive'],
          'x-entity-presentation': {
            enumLabels: {
              active: 'ACTIVE',
              inactive: 'INACTIVE',
              completed: 'COMPLETED',
            },
          },
        },
      },
    },
    {
      id: 'questionnaire_response',
      name: 'Questionnaire Response',
      label: 'QUESTIONNAIRE_RESPONSE',
      type: 'cohortRule',
      rules: {
        any_string_in_list_match_ignore_case: [
          {
            var: '{datapoint}',
          },
          '{answer_codes}',
        ],
      },
      inputs: {
        datapoint: {
          type: 'string',
          'x-entity-label': {
            key: 'QUESTIONNAIRE_ID',
          },
        },
        answer_codes: {
          type: 'array',
          'x-entity-label': {
            key: 'ANSWER_CODES',
          },
          items: {
            type: 'string',
            'x-entity-label': {
              key: 'ANSWER_CODES',
            },
            'x-entity-presentation': {
              hideLabel: true,
            },
          },
        },
      },
    },
    {
      id: 'customer_provided_datapoint_string',
      name: 'Customer Provided Datapoint (string)',
      label: 'CUSTOMER_PROVIDED_DATAPOINT_STRING',
      type: 'cohortRule',
      rules: {
        '==': [
          {
            var: '{datapoint}',
          },
          '{value}',
        ],
      },
      inputs: {
        datapoint: {
          type: 'string',
          'x-entity-label': {
            key: 'DATAPOINT',
          },
          'x-entity-data-point': {
            dataPointSources: ['dml'],
          },
        },
        value: {
          type: 'string',
          'x-entity-label': {
            key: 'VALUE',
          },
        },
      },
    },
    {
      id: 'customer_provided_datapoint_number',
      name: 'Customer Provided Datapoint (number)',
      label: 'CUSTOMER_PROVIDED_DATAPOINT_NUMBER',
      type: 'cohortRule',
      rules: {
        '{numericOperator}': [
          {
            var: '{datapoint}',
          },
          '{value}',
        ],
      },
      inputs: {
        datapoint: {
          type: 'string',
          'x-entity-label': {
            key: 'DATAPOINT',
          },
          'x-entity-data-point': {
            dataPointSources: ['dml'],
          },
        },
        numericOperator: {
          type: 'string',
          'x-entity-presentation': {
            enumLabels: {
              '===': 'EQUALS',
              '<': 'LESS_THAN',
              '>': 'GREATER_THAN',
            },
          },
          'x-entity-label': {
            key: 'OPERATOR',
          },
          enum: numericOperators,
        },
        value: {
          type: 'integer',
          'x-entity-label': {
            key: 'VALUE',
          },
        },
      },
    },
    {
      id: 'customer_provided_datapoint',
      name: 'Customer Provided Datapoint',
      label: 'CUSTOMER_PROVIDED_DATAPOINT_TRUE',
      type: 'cohortRule',
      rules: {
        '==': [
          {
            var: '{datapoint}',
          },
          true,
        ],
      },
      inputs: {
        datapoint: {
          type: 'string',
          'x-entity-label': {
            key: 'DATAPOINT',
          },
          'x-entity-data-point': {
            dataPointSources: ['dml'],
          },
        },
      },
    },
  ];

export const commonCohortRulesTemplates = commonCohortRulesTemplatePartials.map(
  mapRulesTemplatesForEntity('code-system-concept'),
);

export const commonRulesTemplates = [
  ...commonActivityRulesTemplates,
  ...commonCampaignRulesTemplates,
  ...commonMasonryFilterRulesTemplates,
  ...commonCohortRulesTemplates,
].reduce(
  (rules, rule) => ({
    ...rules,
    [rule.id]: rule,
  }),
  {},
);
