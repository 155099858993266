import * as React from 'react';
import {
  StackLayout,
  Modal,
  Button,
  HeadingText,
  HeadingBar,
  InputStatusMessage,
  Box,
  StackItem,
  ParagraphText,
  SkeletonRectangle,
  VisuallyHidden,
} from '@leagueplatform/genesis-core';
import {
  SearchFolderIconSvg,
  TextActionButtonIcon,
} from '@web-config-app/core-react-ui';
import type { DataPoints, ParentId } from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import { useNavigateDataPoints } from '../../hooks/use-navigate-date-points/use-navigate-data-points.hook';
import { DataPointBreadcrumbs } from '../data-point-breadcrumbs/data-point-breadcrumbs.component';
import { SelectDataPoint } from '../select-data-point/select-data-point.component';

const headingId = 'data-point-modal-heading';
const containerId = 'select-data-point-container';

const assignFocusToContainer = () => {
  document.getElementById(containerId)?.focus();
};

export interface DataPointModalProps {
  onSelected: Function;
  dataPoints: DataPoints;
  isLoading?: boolean;
  isError?: boolean;
  disabled?: boolean;
}

export const DataPointModal = ({
  onSelected,
  dataPoints,
  isError,
  isLoading,
  disabled,
}: DataPointModalProps) => {
  const { formatMessage } = useIntl();
  const {
    currentCategory,
    categoryDataPoints,
    categoryBreadcrumbs,
    hasSelectableDataPoint,
    isTopLevelCategory,
    onGoBack,
    onNavigate,
    selectableDataPoints,
  } = useNavigateDataPoints(dataPoints);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedDataPoint, setSelectedDataPoint] = React.useState(
    null as ParentId,
  );
  const [errorStatusMsg, setErrorStatusMsg] = React.useState(
    null as string | null,
  );

  const headingText = isTopLevelCategory
    ? formatMessage({ id: 'DATA_POINT_MODAL_HEADING' })
    : currentCategory?.label;

  const onNavigateBack = () => {
    onGoBack();
    setSelectedDataPoint(null);
    assignFocusToContainer();
  };

  const onNavigateCategory = (categoryId: ParentId) => {
    onNavigate(categoryId);
    setSelectedDataPoint(null);
    assignFocusToContainer();
  };

  const onSelectDataPoint = (dataPointId: ParentId) => {
    if (errorStatusMsg) setErrorStatusMsg(null);
    setSelectedDataPoint(dataPointId);
  };

  const onApplySelectedDataPoint = React.useCallback(() => {
    if (!selectedDataPoint) {
      setErrorStatusMsg('DATA_POINT_EMPTY_SELECTION');
      return;
    }

    onSelected(selectedDataPoint);

    setModalOpen(false);
  }, [selectedDataPoint, onSelected]);

  const onModalOpenChange = React.useCallback(() => {
    setModalOpen((previous) => !previous);
  }, []);

  // Reset messaging, selections, and current category level on close modal
  React.useEffect(() => {
    if (!modalOpen) {
      setErrorStatusMsg(null);
      setSelectedDataPoint(null);
      onNavigate(null);
    }
  }, [modalOpen, onNavigate]);

  // Reset selectedDataPoint to first item when navigating categories
  React.useEffect(() => {
    if (modalOpen && hasSelectableDataPoint && !selectedDataPoint) {
      setSelectedDataPoint(selectableDataPoints[0].id);
    }
  }, [
    modalOpen,
    selectedDataPoint,
    hasSelectableDataPoint,
    selectableDataPoints,
  ]);

  const noValidDataPoints = categoryDataPoints.length === 0;

  return (
    <Modal.Root open={modalOpen} onOpenChange={onModalOpenChange}>
      <Modal.Trigger>
        <TextActionButtonIcon
          disabled={disabled}
          label="Browse"
          icon={<SearchFolderIconSvg />}
        />
      </Modal.Trigger>
      <Modal.Content
        layout="fullscreen"
        showCloseButton={false}
        padding="$none"
        css={{
          '.GDS-modal-content': {
            paddingTop: '$none',
            paddingBottom: '$one',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <HeadingBar
          rightAction={
            <Modal.Close>
              <Button
                priority="tertiary"
                quiet
                icon="tinyClose"
                hideLabel
                size="medium"
              >
                {formatMessage({ id: 'CLOSE' })}
              </Button>
            </Modal.Close>
          }
          leftAction={
            !isTopLevelCategory && (
              <Button
                hideLabel
                icon="tinyBackButton"
                onClick={onNavigateBack}
                priority="tertiary"
                quiet
                size="medium"
              >
                {formatMessage({ id: 'BACK' })}
              </Button>
            )
          }
        />
        <StackLayout
          css={{
            width: '100%',
            paddingRight: '$two',
            paddingLeft: '$two',
            minHeight: '50vh',
            justifyContent: 'flex-start',
          }}
        >
          <DataPointBreadcrumbs
            breadcrumbs={categoryBreadcrumbs}
            onSelectCrumb={onNavigateCategory}
          />

          <Modal.Title>
            <HeadingText
              css={{
                marginBottom: '$half',
                textTransform: 'capitalize',
              }}
              id={headingId}
              level="2"
              size="lg"
            >
              {headingText}
            </HeadingText>
          </Modal.Title>

          {isTopLevelCategory && !isLoading && (
            <ParagraphText>
              {formatMessage({ id: 'DATA_POINT_MODAL_INSTRUCTIONS' })}
            </ParagraphText>
          )}

          {isLoading && (
            <Box data-testid="loading-state" css={{ width: '100%' }}>
              <VisuallyHidden>
                {formatMessage({ id: 'LOADING' })}
              </VisuallyHidden>
              <SkeletonRectangle height="35vh" css={{ marginTop: '$two' }} />
            </Box>
          )}

          {isError && (
            <ParagraphText
              data-testid="error-state"
              css={{ marginTop: '$two' }}
            >
              {formatMessage({ id: 'DATA_POINT_LOADING_ERROR' })}
            </ParagraphText>
          )}

          {noValidDataPoints && (
            <ParagraphText
              data-testid="no-data-points"
              css={{ marginTop: '$two' }}
            >
              {formatMessage({ id: 'NO_VALID_DATA_POINTS' })}
            </ParagraphText>
          )}

          <StackLayout
            css={{
              width: '100%',
              overflow: 'auto',
            }}
          >
            <SelectDataPoint
              containerId={containerId}
              dataPoints={categoryDataPoints}
              labelledBy={headingId}
              onNavigate={onNavigateCategory}
              onSelectItem={onSelectDataPoint}
              selectedDataPoint={selectedDataPoint}
            />
          </StackLayout>

          {hasSelectableDataPoint && (
            <StackItem
              css={{
                marginTop: '$half',
                marginLeft: 'auto',
                alignItems: 'flex-end',
              }}
            >
              <Button
                css={{ width: 'max-content' }}
                priority="primary"
                size="medium"
                onClick={onApplySelectedDataPoint}
              >
                {formatMessage({ id: 'SELECT_DATA_POINT' })}
              </Button>

              <Box
                aria-live="polite"
                css={{
                  marginLeft: 'auto',
                  marginTop: '$half',
                  height: '$one',
                }}
                id="statusMessage"
              >
                {errorStatusMsg ? (
                  <InputStatusMessage inputStatus="error" id="statusComponent">
                    {formatMessage({ id: errorStatusMsg })}
                  </InputStatusMessage>
                ) : null}
              </Box>
            </StackItem>
          )}
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
