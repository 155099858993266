import * as React from 'react';
import { UtilityText, StatusBadge } from '@leagueplatform/genesis-core';
import {
  Entity,
  EntityDetail,
  getEntityStatusBadgeProps,
} from '@web-config-app/core';
import { useIntl } from '@leagueplatform/locales';
import { isUsingConfigAppBackend } from '@web-config-app/common';
import { useConfigApp } from '@web-config-app/core-react';
import { FormattedRelativeTime } from 'react-intl';
import {
  Table,
  TableRow,
  TableHeading,
  TableCellWithLink,
  TableCell,
} from '../table-pieces.component';
import { getTimeSinceLastUpdated } from '../get-time-since-last-updated';
import { EntityListTableSkeleton } from '../entity-list-table-skeleton/entity-list-table-skeleton.component';
import { ValueWithCopyButton } from '../../value-with-copy-button/value-with-copy-button.component';

interface EntityListTableProps {
  getItemLinkUrl: (id: string) => string;
  getItemName: (data: EntityDetail) => string;
  entityData?: EntityDetail[];
  isLoading?: boolean;
  entity: Entity;
}

export const LinkableEntityListTable = ({
  getItemLinkUrl,
  getItemName,
  entityData,
  isLoading,
  entity,
}: EntityListTableProps) => {
  const { formatMessage } = useIntl();
  const { isUsingConfigAppBackend: tenantIsUsingConfigAppBackend } =
    useConfigApp();
  return (
    <Table>
      <thead>
        <tr>
          <TableHeading>{formatMessage({ id: 'NAME' })}</TableHeading>
          <TableHeading css={{ width: '20%' }}>
            {formatMessage({ id: 'ID' })}
          </TableHeading>
          <TableHeading css={{ width: '15%' }}>
            {formatMessage({ id: 'LAST_UPDATED' })}
          </TableHeading>
          <TableHeading css={{ width: '15%' }}>
            {formatMessage({ id: 'STATUS' })}
          </TableHeading>
        </tr>
      </thead>

      {isLoading ? (
        <EntityListTableSkeleton />
      ) : (
        <tbody>
          {!isLoading &&
            entityData?.map((item) => {
              const linkToEntityDetail = getItemLinkUrl(item.id);
              const statusBadgeProps = getEntityStatusBadgeProps(
                item.attributes.entityMetadata.status,
              );
              const timeSinceLastUpdated = getTimeSinceLastUpdated(
                item.attributes.entityMetadata.lastUpdatedDate,
              );
              const shouldUseDomainEntityId = isUsingConfigAppBackend({
                tenantLevelOverride: tenantIsUsingConfigAppBackend,
                entityLevelOverride: entity.isUsingConfigAppBackend,
              });
              const itemId = shouldUseDomainEntityId
                ? item.attributes.entityMetadata.domainEntityId
                : item.id;
              return (
                <TableRow key={item.id}>
                  {/* NAME */}
                  <TableCellWithLink
                    as="th"
                    scope="row"
                    to={linkToEntityDetail}
                  >
                    <UtilityText>
                      {getItemName(item) || formatMessage({ id: 'UNTITLED' })}
                    </UtilityText>
                  </TableCellWithLink>

                  {/* ID */}
                  <TableCell>
                    {itemId && (
                      <ValueWithCopyButton
                        value={itemId}
                        buttonLabel={formatMessage({ id: 'COPY_ENTITY_ID' })}
                      />
                    )}
                  </TableCell>

                  {/* DATE */}
                  <TableCellWithLink to={linkToEntityDetail} tabIndex={-1}>
                    <UtilityText>
                      <FormattedRelativeTime
                        value={timeSinceLastUpdated}
                        numeric="auto"
                        updateIntervalInSeconds={60}
                      />
                    </UtilityText>
                  </TableCellWithLink>

                  {/* STATUS */}
                  <TableCellWithLink to={linkToEntityDetail} tabIndex={-1}>
                    <StatusBadge
                      showLight={false}
                      status={statusBadgeProps.genesisStatus}
                      label={formatMessage({
                        id: statusBadgeProps.labelKey,
                      })}
                    />
                  </TableCellWithLink>
                </TableRow>
              );
            })}
        </tbody>
      )}
    </Table>
  );
};
