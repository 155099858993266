import * as React from 'react';
import { Card, GDSColor } from '@leagueplatform/genesis-core';
import EmptyDefaultImage from '../../assets/empty.png';

type EmptyStateCardProps = {
  image?: string;
  hideImage?: boolean;
  imageAlt?: string;
  backgroundColor?: GDSColor;
};

export const EmptyStateCard: React.FC<
  React.PropsWithChildren<EmptyStateCardProps>
> = ({
  image,
  hideImage = false,
  imageAlt = '',
  backgroundColor = '$surfaceBackgroundPrimary',
  children,
}) => (
  <Card.Flat
    css={{
      border: '1px solid $onSurfaceBorderSubdued',
      padding: '$twoAndHalf',
      zIndex: -1,
      backgroundColor: `${backgroundColor}`,
    }}
  >
    {!hideImage && (
      <Card.Image
        image={image || EmptyDefaultImage}
        css={{ height: '$five:', width: '$five', margin: '0 auto' }}
        imageAlt={imageAlt}
      />
    )}
    <Card.Section css={{ textAlign: 'center' }}>{children}</Card.Section>
  </Card.Flat>
);
